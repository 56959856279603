.header {
  margin-bottom: 25px;
  padding: 15px;
  background-color: #2d2d2d;

  figure {
    text-align: center;
    margin: 0;
    padding: 0;
    img {
      max-width: 320px;
    }
  }
}

.nav {
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  gap: 25px;

  a {
    color: white;
    text-decoration: none;
  }
}
