body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1366px;
  margin: auto;
}

.titles {
  background-color: #2d2d2d;
  color: white;
  font-weight: bold;
}

input {
  padding: 8px;
}

.row {
  display: flex;
  border-top: solid 1px black;
  justify-content: center;
  align-items: center;

  &:last-child {
    border-bottom: solid 1px black;
  }

  &:hover {
    background-color: #2d2d2d;
    color: white;
  }

  a {
    color: inherit;
  }
}

.col {
  width: 100%;
  padding: 5px;
  border-left: solid 1px black;
  &:last-child {
    border-right: solid 1px black;
  }
}

.small {
  text-align: center;
  width: 5%;
}

.mid {
  text-align: center;
  width: 35%;
}
