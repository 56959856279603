$color-primary: #272727;
$color-secundary: #ffc600;
$blanco: rgb(236, 236, 236);
$warning: #ff4545;

.container {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;

  .info {
    display: flex;
  }

  article {
    width: 100%;
    margin-bottom: 25px;

    span {
      font-weight: bold;
    }
  }

  .address {
    width: 100%;
    margin-left: 30px;
  }

  .shipping {
    width: 100%;
    margin-left: 30px;
  }

  .itemList {
    display: flex;
    gap: 50px;

    h4 {
      margin-bottom: 10px;
    }
  }

  .product {
    .details {
      margin-top: 10px;
      margin-left: 10px;
    }
    span {
      font-weight: bold;
    }
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 18px;
}

a {
  text-decoration: none;
}

h1 {
  position: relative;
  margin-bottom: 50px;
  letter-spacing: 0.2em;
  text-align: center;
  font-size: 32px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    bottom: -5px;
    background-color: $color-primary;
  }
}

h2 {
  position: relative;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  font-size: 26px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    height: 3px;
    left: 0;
    border-radius: 3px;
    bottom: -5px;
    background-color: $color-primary;
  }
}

h3 {
  margin-top: 15px;
  margin-bottom: 15px;
  letter-spacing: 0.05em;
}
