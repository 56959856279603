.login {
  max-width: 300px;
  margin: auto;

  .field {
    margin-bottom: 10px;
    label {
      display: block;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      padding: 5px;
    }
  }

  .btn {
    text-align: center;
  }
}

button {
  background-color: #222;
  border: none;
  padding: 10px 5px;
  color: white;
  text-transform: uppercase;
}
